<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="none" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <c-card title="위험등록부 등재 위험기준" class="cardClassDetailForm">
      <template slot="card-description">
        <font class="text-negative" style="font-size:0.9em;font-weight:500;">
          (※ 입력한 위험기준 이상의 위험수준을 위험성평가 때 입력할 경우 자동으로 위험등록부란이 체크됩니다.)
        </font>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :required="true"
            :editable="editable"
            codeGroupCd="RAM_RISK_LEVEL_CD"
            type="none"
            itemText="codeName"
            itemValue="code"
            name="ramRiskLevelCd"
            label="위험기준"
            v-model="riskStandard.ramRiskLevelCd"
            @datachange="saveRiskStandard"
          ></c-select>
        </div>
      </template>
    </c-card>
    <!-- 3단계 판단기준 목록 -->
    <c-table
      ref="table"
      class="q-mt-sm"
      title="LBL0001275"
      tableId="riskHazrd01"
      :columns="grid.columns"
      :data="grid.data"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 저장 -->
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="PUT"
            label="LBLSAVE" 
            icon="save" 
            @beforeAction="saveRiskThirdStandard"
            @btnCallback="saveRiskThirdStandardCallback"/>
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'riskColor'">
          <div class="cursor-pointer" :style="`background-color:${props.row.riskColor};height:100%;`"></div>
          <q-popup-proxy>
            <q-color
              v-model="props.row.riskColor"
              no-header
              no-footer
              class="my-picker"
              @input="datachange(props.row)"
            />
          </q-popup-proxy>
        </template>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'riskThirdStandard',
  data() {
    return {
      searchParam: {
        plantCd: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'ramRiskLevelName',
            field: 'ramRiskLevelName',
            // 위험수준
            label: 'LBL0001274',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'ramRiskAcceptCd',
            field: 'ramRiskAcceptCd',
            // 허용가능 수준
            label: 'LBL0001273',
            align: 'left',
            style: 'width:100px',
            sortable: true,
            required: true,
            type: 'select',
            itemText: 'codeName',
            itemValue: 'code',
            codeGroupCd: 'RAM_RISK_ACCEPT_CD'
          },
          {
            name: 'riskColor',
            field: 'riskColor',
            label: '위험색상<br/>(색상을 클릭하여 변경하세요)',
            align: 'center',
            style: 'width:150px',
            sortable: true,
            type: 'custom'
          },
          {
            name: 'judgmentCriteria',
            field: 'judgmentCriteria',
            // 판단기준
            label: 'LBL0001271',
            style: 'width:300px',
            align: 'left',
            sortable: true,
            type: 'textarea',
          },
        ],
        data: [],
      },
      riskStandard: {
        plantCd: '',
        ramRiskLevelCd: null,
        postRamRiskLevelCd: null,
      },
      editable: true,
      isSave: false,
      listUrl: '',
      checkUrl: '',
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.riskThirdStandard.list.url
      this.checkUrl = selectConfig.ram.riskThirdStandard.check.url
      this.saveUrl = transactionConfig.ram.riskThirdStandard.save.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        let standards = this.$_.filter(this.grid.data, { riskRegisterFlag: 'Y' })
        this.$set(this.riskStandard, 'plantCd', this.searchParam.plantCd)
        if (standards && standards.length > 0) {
          standards = this.$_.orderBy(standards, ['ramRiskLevelCd'], ['desc']);
          this.$set(this.riskStandard, 'ramRiskLevelCd', standards[0].ramRiskLevelCd)
          this.$set(this.riskStandard, 'postRamRiskLevelCd', standards[0].ramRiskLevelCd)
        } else {
          // 상
          let standard = this.$_.find(this.grid.data, { ramRiskLevelCd: 'RL00000001' })
          this.$set(this.riskStandard, 'ramRiskLevelCd', standard.ramRiskLevelCd)
          this.$set(this.riskStandard, 'postRamRiskLevelCd', standard.ramRiskLevelCd)
        }
      },);
    },
    datachange(row) {
      if (row.editFlag !== "C") {
        row.editFlag = "U";
        row.chgUserId = this.$store.getters.user.userId;
      }
    },
    saveRiskStandard () {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGSAVE', // 저장하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$_.forEach(this.grid.data, item => {
            if (item.ramRiskLevelCd <= this.riskStandard.ramRiskLevelCd) {
              item.riskRegisterFlag = 'Y'
            } else {
              item.riskRegisterFlag = 'N'
            }
            item.editFlag = "U";
            item.chgUserId = this.$store.getters.user.userId;
          })
          this.isSave = !this.isSave
        },
        // 취소 callback 함수
        cancelCallback: () => {
          this.$set(this.riskStandard, 'ramRiskLevelCd', this.riskStandard.postRamRiskLevelCd)
        },
      });
    },
    saveRiskThirdStandard() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        let plants = this.$_.uniq(this.$_.map(this.grid.data, 'plantCd'))
        if (plants && plants.length > 0) {
          let check = this.$_.isEmpty(this.$_.pickBy(this.$_.countBy(this.grid.data, item => {
            return [item.plantCd, item.ramRiskLevelCd]
          }), count => {
            return count > 1
          }))

          if (check) {
            this.$http.url = this.checkUrl;
            this.$http.type = 'POST';
            this.$http.param = this.$_.map(this.$_.filter(this.grid.data, item => {
              return item.editFlag === 'C' || item.editFlag === 'U'
            }), item => {
              return {
                plantCd: item.plantCd,
                ramRiskLevelCd: item.ramRiskLevelCd,
                ramRiskThirdStandardId: item.editFlag !== 'C' ? item.ramRiskThirdStandardId : null,
              }
            }),
            this.$http.request((_result) => {
              if (_result.data > 0) {
                window.getApp.$emit('ALERT', {
                  title: 'LBLGUIDE', // 안내
                  message: 'MSG0000407', // 동일한 데이터가 존재합니다. \n[사업장, 위험수준]
                  type: 'warning', // success / info / warning / error
                });
                this.getList();
              } else {
                window.getApp.$emit('CONFIRM', {
                  title: 'LBLCONFIRM',
                  message: 'MSGSAVE', // 저장하시겠습니까?
                  // TODO : 필요시 추가하세요.
                  type: 'info', // success / info / warning / error
                  // 확인 callback 함수
                  confirmCallback: () => {
                    this.isSave = !this.isSave
                  },
                  // 취소 callback 함수
                  cancelCallback: () => {
                  },
                });
              }
            },);
          } else {
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              message: 'MSG0000407', // 동일한 데이터가 존재합니다. \n[사업장, 위험수준]
              type: 'warning', // success / info / warning / error
            });
          }
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: 'MSG0000408', // 저장할 데이터가 없습니다.
            type: 'warning', // success / info / warning / error
          });
        }
      }
    },
    saveRiskThirdStandardCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: { type: "none", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "위험등록부 등재 위험기준" },
        },
        [
          _c(
            "template",
            { slot: "card-description" },
            [
              _c(
                "font",
                {
                  staticClass: "text-negative",
                  staticStyle: { "font-size": "0.9em", "font-weight": "500" },
                },
                [
                  _vm._v(
                    " (※ 입력한 위험기준 이상의 위험수준을 위험성평가 때 입력할 경우 자동으로 위험등록부란이 체크됩니다.) "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    codeGroupCd: "RAM_RISK_LEVEL_CD",
                    type: "none",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "ramRiskLevelCd",
                    label: "위험기준",
                  },
                  on: { datachange: _vm.saveRiskStandard },
                  model: {
                    value: _vm.riskStandard.ramRiskLevelCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.riskStandard, "ramRiskLevelCd", $$v)
                    },
                    expression: "riskStandard.ramRiskLevelCd",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          staticClass: "q-mt-sm",
          attrs: {
            title: "LBL0001275",
            tableId: "riskHazrd01",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            editable: _vm.editable,
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "riskColor"
                    ? [
                        _c("div", {
                          staticClass: "cursor-pointer",
                          style: `background-color:${props.row.riskColor};height:100%;`,
                        }),
                        _c(
                          "q-popup-proxy",
                          [
                            _c("q-color", {
                              staticClass: "my-picker",
                              attrs: { "no-header": "", "no-footer": "" },
                              on: {
                                input: function ($event) {
                                  return _vm.datachange(props.row)
                                },
                              },
                              model: {
                                value: props.row.riskColor,
                                callback: function ($$v) {
                                  _vm.$set(props.row, "riskColor", $$v)
                                },
                                expression: "props.row.riskColor",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: {
                          isSubmit: _vm.isSave,
                          url: _vm.saveUrl,
                          param: _vm.grid.data,
                          mappingType: "PUT",
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveRiskThirdStandard,
                          btnCallback: _vm.saveRiskThirdStandardCallback,
                        },
                      })
                    : _vm._e(),
                  _c("c-btn", {
                    attrs: { label: "LBLSEARCH", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }